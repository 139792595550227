@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div-image--api {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 250px;
}

.content-div {
  /* transition: 0.5s; */
}

.content-div:hover {
  /* background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.8)
  ); */
  background-color: rgba(0, 0, 0, 0.8);
}

.grid-height {
  min-height: 32.25rem;
}
