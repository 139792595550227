$tablet-breakpoint:768px;
$desktop-breakpoint:12680px;

@mixin tablet {
   @media (min-width:$tablet-breakpoint){
    @content;
   }
}

@mixin desktop {
    @media (min-width:$desktop-breakpoint){
        @content
    }
}


@keyframes sidebar-appear {
    0% {width:0;}
    100%{width:16rem;}
}

@keyframes sidebar-appear-text {
    0% {width:0rem;}
    100%{width:16rem;}
}

@keyframes sidebar-disappear {
    0% {width:16rem;}
    100%{width:0;}
}

@keyframes sidebar-disappear-text {
    0% {width:16rem;}
    100%{width:0rem;}
}


.open-animation  {
    animation: sidebar-appear 0.3s ease-in 1;
}


.open-animation-text {
     animation: sidebar-appear-text 0.1s ease-in 1;
}


.close-animation  {
    animation:sidebar-disappear 0.3s ease-out 1;
}

.close-animation-text {
     animation:sidebar-disappear-text 0.8s ease-out 1;
}

.hamburger {
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    @include tablet{
      display:none
    }

    span {
      width: 100%;
      height: 2px;
      background-color: white;
      transform-origin: left;
      transition: all 0.3s ease;
      @include desktop {
        display:none
      }
    }
}

  .active {


    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
}